<template>
    <div
        class="my-auto animate__animated animate__pulse"
        :style="{ '--animate-duration': '600ms' }"
    >
        <div class="match">
            <div class="match__photo">
                <ImageResource :src="user?.thumbnail" />
            </div>
            <div class="match__photo">
                <ImageResource :src="attendee?.thumbnail" />
            </div>
            <span>
                <i></i>
            </span>
        </div>

        <h2>{{ __('Je to partička!') }}</h2>
        <p>{{ description || __('Našli sme parťáka do tvojej partičky!') }}</p>
    </div>

    <div class="container__footer">
        <div class="form-group" @click="eventStore.goToChat(event)">
            <FormText rounded :label="__('Napíš parťákovi')" readonly />
            <button class="form-send-btn"></button>
        </div>
        <span @click="emit('next')">
            {{ __('Napíšem neskôr') }}
        </span>
    </div>
</template>

<script setup>
const eventStore = useEventStore();
const authStore = useAuthStore();

const emit = defineEmits(['next']);

const props = defineProps({
    event: {},
    attendee: {},
    description: {},
});

const user = computed(() => {
    return authStore.user;
});
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2rem;
}

.match {
    display: flex;
    position: relative;
    justify-content: center;

    span {
        width: 5.6rem;
        height: 5.6rem;
        background: var(--ion-color-primary);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: -1.5rem;
        margin-left: -2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            width: 3.5rem;
            height: 3.5rem;
            background: #fff;
            mask: url($basePath + 'icons/football.svg') no-repeat center;
        }
    }
}

.match__photo {
    border-radius: 1rem;
    overflow: hidden;
    border: 4px solid #81cbd3;
    width: 16rem;
    height: 20rem;

    &:first-child {
        transform: rotate(-12deg);
    }

    &:nth-child(2) {
        transform: rotate(12deg);
    }

    :deep(img) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: white;
    }
}

h2 {
    font-family: var(--secondary-font-family);
    font-size: 3.2rem;
    font-weight: 800;
    margin: 3rem 0 0 0;
    text-align: center;
    line-height: 1;
}

p {
    margin: 1.2rem 0 0 0;
    font-size: 1.1rem;
    font-weight: 300;
    text-align: center;
}

.form-group {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2222;
    }
}

.form-send-btn {
    position: absolute;
    right: 1rem;
    width: 4.6rem;
    height: 4.6rem;
    top: 0;
    background: url($basePath + 'icons/send.svg') no-repeat center;
    border: 0;
    appearance: none;
}

.container__footer > span {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
    display: block;
    margin-top: 2rem;
}
</style>
